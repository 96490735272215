@import '../../styles/globals.scss';

.container {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	text-align: center;
	gap: 50px;
	max-width: 100%;
	margin: 20px 20px 100px 20px;
	@include for-desktop-up {
		display: grid;
		text-align: start;
		align-items: flex-start;
		text-align: flex-start;
		max-width: 760px;
		margin: 60px auto 100px auto;
	}

	.tech {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		div {
			margin-bottom: 10px;
		}
		@include for-desktop-up {
			align-items: flex-start;
			justify-content: flex-start;
			position: fixed;
		}
		>div {
			>div {
				display: flex;
				gap: 10px;
				
				img {
					height: 40px;
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  				filter: grayscale(100%);
					
				}
				:hover{
					-webkit-filter: grayscale(0%); 
					filter: grayscale(0%);
				}
				.iconContainer {
					position: relative;
					span {
						display: none;
						position: absolute;
						left: 0px;
						bottom: -15px;
					}
				}
			}
		}
	}

	.about {
		margin-left: 0;
		width: 90%;
		@include for-desktop-up  {
			margin-left: 400px;
			width: 400px;
		}
	}

	:last-child {
		h1 {
			margin-bottom: 20px;
			text-transform: uppercase;
		}
	}
}