@import '../../styles/globals.scss';

.container {
	height: 50px;
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	@include for-tablet-up {
		height: 80px;
		padding-right: 100px;
		justify-content: flex-end;
	}
	ul {
		display: flex;
		gap: 20px;
		
		a{
			display: flex;
			align-items: center;
			text-decoration: none;
			i {
				font-size: 22px;
			}
			span {
				text-decoration: solid;
			}
		}
	}
}
