@import './globals.scss';

* {
	box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
p,h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}

body {
	margin: 0;
	font-family: 'Kulim Park', sans-serif;
	min-height: 100vh;
	transition-duration: .3s;
}

#root {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

p {
	line-height: 1.8rem;
}

a {
	color: #1b1a1a ;
	border: none;
	background-color: transparent;
	text-decoration: underline;
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

input,textarea{
	background-color: #F4F4F4;
	border: none;
	padding: 10px 5px;
}


textarea{
	min-height: 80px;
}

button {
	border: none;
	background-color: transparent;
	text-decoration: underline;
}

#footer {
	transition-duration: .3s;
}