@import '../../styles/globals.scss';
.container {
	display: none;
	@include for-desktop-up {
		display: block;
	}
		.circle {
			position: absolute;
			height: 50px;
			width: 50px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -2;
			transition: 1.5s all ease-out;
			
		}
		.circle2 {
			position: absolute;
			height: 80px;
			width: 80px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle3 {
			position: absolute;
			height: 130px;
			width: 130px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle4 {
			position: absolute;
			height: 200px;
			width: 200px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle5 {
			position: absolute;
			height: 280px;
			width: 280px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle6 {
			position: absolute;
			height: 380px;
			width: 380px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle7 {
			position: absolute;
			height: 500px;
			width: 500px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle8 {
			position: absolute;
			height: 720px;
			width: 720px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
		.circle9 {
			position: absolute;
			height: 1100px;
			width: 1100px;
			border: 1px solid #A3A3A3;
			background: transparent;
			border-radius: 50%;
			z-index: -3;
			transition: 1.5s all ease-out;
			
		}
}