@import '../../styles/globals.scss';

.container {
	margin: 60px auto 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	overflow-x: hidden;
	@include for-tablet-up {
		display: block;
		width: 750px;
		flex-direction: column;
	}
	@include for-desktop-up {
		overflow-x:initial;
	}
	.main {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 50px;
		@include for-tablet-up {
			display: grid;
			grid-template-columns: 250px 1fr;
			gap: 80px;
			margin-bottom: 50px;
			min-height: 330px;
		}
		.preview {
			display: flex;
			flex-direction: column;
			gap: 10px;
			justify-content: flex-start;
			margin-top: 5px;
			align-items: center;
			img {
				width: 200px;
				height: 85px;
				margin-bottom: 30px;
			}
			.links{
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 10px;
				@include for-tablet-up {
					align-items: flex-start;
				}
				div {
					display: flex;
					align-items: center;
					gap: 5px;

					a{
						text-decoration: none;
						border-bottom: 1px solid rgba(0, 0, 0, 1);
						transition: border-bottom 0.3s;
					}
					a:hover {
						border-bottom: 1px solid rgba(0, 0, 0, 0);
					}
				}
			}
		}
		.details {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 10px;
			margin: 0 10px;
			@include for-tablet-up {
				margin: 0;
				text-align: start;
			}
			h1 {
				display: none;
			}
			@include for-tablet-up {
				margin-bottom: 10px;
				h1 {
					display: block;
				}
			}
			.tools{
				display: flex;
				gap: 20px;
			}
		}
	}
	.navigation {
		margin-bottom: 10px;
		margin: 0 20px;                                                         
		display: flex;
		justify-content: space-between;
		-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
		@include for-tablet-up {
			margin-bottom: 100px;
		}

		a {
			text-decoration: none;
		}
		.navTitle{
			position: relative;
			display: flex;
			flex-direction: column;
		}
		.navButton {
			font-size: 20px;
			cursor: pointer;
			span {
				display: none;
			}
			@include for-tablet-up {
				span {
					display: inline;
				}
			}
		}
	}
}
