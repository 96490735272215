@import '../../styles/globals.scss';

.container {
	display: flex;
	justify-content: center;
	padding: 20px 0;

	@include for-tablet-up {
		flex-direction: row;
		justify-content: flex-end;
		padding: 40px 100px;
	}

	ul {
		display: flex;
		gap: 25px;
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}